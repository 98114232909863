body {
  height: 100%;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", sans-serif;
}
