.gradient {
  background: rgb(52, 47, 138);
  background: linear-gradient(
    90deg,
    rgba(52, 47, 138, 1) 0%,
    rgba(9, 9, 121, 1) 63%,
    rgba(1, 56, 138, 0.8379726890756303) 99%
  );
}

.page-header {
  font-size: 1.25rem;
  color: #fff;
}

.house-image {
  width: 100%;
  height: auto;
  box-shadow: 0 0 16px 0px whitesmoke;
  border-radius: 20px;
}

.feature {
  color: white;
}

.feature ul {
  padding-left: 2rem;
  list-style-type: none;
}

.feature ul li {
  padding-left: 2rem;
  background-image: url(./assets/svg/check.svg);
  background-repeat: no-repeat;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.featureIcon {
  color: white;
  height: 5rem;
  width: 5rem;
  font-size: 1.75rem;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.stats {
  margin-top: 100px;
}

.pricing {
  margin: 100px;
}

.flag {
  flex: 16%;
}

.registerCallToAction {
  margin: 100px 0 300px 0;
}
